// React
import React, {
    useState,
    useEffect
  } from 'react';

// Loading
import Loading from '../loading/Loading.js';

// Plotly
import Plot from 'react-plotly.js';

// Resizing
import useResizeObserver from 'use-resize-observer';

// Display overview for a campaign
function Overview(props) {
    const id = props.id;
    const size = props.size;

    // State for storing results from the API
    const [overviewResults, setOverviewResults] = useState({});
    const [exclusions, setExclusions] = useState([]);

    // Update the exclusion list
    const updateExclusion = (event) => { 
        var value = event.target.value;
        if (exclusions.includes(value)) {
            setExclusions(exclusions.filter(x => x !== value));
        } else {
            setExclusions(current => [...current, value]);
        }
    };

    // Get the resizing info
    const { ref, width = 1, height = 1 } = useResizeObserver();

    // Ask the API for the topline question data
    useEffect(() => {

        // Fetch the data
        if (id) {
            fetch('/data/topline_results', {
                method: 'post',
                body: JSON.stringify({
                'campaign-id': id
                }),
                headers: { 'Content-Type': 'application/json' }
            }).then(res => res.json()).then(data => {
                setOverviewResults(data);
            });
        }
    
    // Update when id changes
    }, [id]);

    // Default data for when the API call has not been processed
    var variables = [];
    var totalKPIs = 0;
    var positiveKPIs = 0;
    var positiveNames = [];
    var nameKPIs = [];
    var liftKPIs = [];
    var barKPIs = [];
    var treatmentKPIs = [];
    var controlKPIs = [];
    var lift10 = [];
    var lift90 = [];
    var minArr = [];
    var maxArr = [];

    // Iterate to add data for the tables and graphs
    Object.keys(overviewResults).forEach(k => {
        variables.push(overviewResults[k].question);
        if (!exclusions.includes(overviewResults[k].question)) {
            totalKPIs += 1;
            if (Math.round(overviewResults[k].avg_lift * 1000) / 10 >= 0) {  // Match the rounding of the table
                positiveKPIs += 1;
                positiveNames.push(overviewResults[k].question);
            };
            var nameKPI = Math.round(overviewResults[k].avg_lift * 1000) / 10 + 'pp ';
            if (overviewResults[k].lift_prob > 0.95) {
                nameKPI += '***';
            } else if (overviewResults[k].lift_prob > 0.9) {
                nameKPI += '**';
            } else if (overviewResults[k].lift_prob > 0.8) {
                nameKPI += '*';
            } else if (overviewResults[k].lift_prob > 0.67) {
                nameKPI += '^';
            }
            nameKPIs.push(
                '<b>' + overviewResults[k].question + '</b><br>' + 
                'avg lift: ' + nameKPI
            );
            barKPIs.push(
                '<b>' + overviewResults[k].question + '</b><br>' + 
                'avg lift: ' + nameKPI
            );
            liftKPIs.push(overviewResults[k].avg_lift);
            treatmentKPIs.push(overviewResults[k].avg_treatment);
            controlKPIs.push(overviewResults[k].avg_control);
            lift10.push(overviewResults[k].avg_lift - overviewResults[k].lift_10_pct);
            lift90.push(overviewResults[k].lift_90_pct - overviewResults[k].avg_lift);
            minArr.push(overviewResults[k].lift_10_pct);
            maxArr.push(overviewResults[k].lift_90_pct);
        }
    });

    // Get the ratio to determine which class we apply to the KPI display box text
    var ratioKPIs = positiveKPIs / totalKPIs;

    // Find the max and min y values for the chart
    var combinedArray = minArr.concat(maxArr);
    var maxVal = Math.max.apply(Math, combinedArray);
    var minVal = Math.min.apply(Math, combinedArray);
    if (Math.abs(minVal) > maxVal) {
        maxVal = Math.abs(minVal);
    } else if (minVal > 0) {
        minVal = 0;
    };
    maxVal += 0.01;
    minVal -= 0.01;

    // Reverse the KPIs
    nameKPIs.reverse();
    liftKPIs.reverse();
    lift10.reverse();
    lift90.reverse();

    // The grouped bar chart data requires Plotly settings, so make the empty object with those settings
    var barControlData = {
        name: 'Control',
        type: 'bar',
        x: barKPIs,
        y: controlKPIs,
        text: controlKPIs,
        hoverinfo: 'skip',
        texttemplate: '%{y:.1%}',
        textfont: { size: 16 },
        marker: { color: '#E7EAED' },
    };
    var barTreatmentData = {
        name: 'Treatment',
        type: 'bar',
        x: barKPIs,
        y: treatmentKPIs,
        text: treatmentKPIs,
        hoverinfo: 'skip',
        texttemplate: '%{y:.1%}',
        textfont: { size: 16 },
        marker: { color: '#3dbaff' },
    };

    // Return the layout for the overview
    return (
        <div className="grid grid-cols-1 md:grid-cols-3 gap-5 justify-items-center content-center">

            {/* Allow for the removal of values */}
            <div className='
                col-span-1 md:col-span-3
                flex 
                w-full
                border
                bg-white
                shadow-lg
                text-center'
            >
                <p className='w-full text-xl mt-5 mb-5 p-2'>
                    Displayed Data
                </p>
                { variables.map((name, i) => (
                    <div key={ i } className='w-3/12 flex flex-col justify-center text-center'>
                        <input 
                            type='checkbox'
                            onChange={ updateExclusion } 
                            value={ name } 
                            checked={ (exclusions.includes(name)) ? false : true }
                            id={ 'check' + i }
                        >
                        </input>
                        <label htmlFor={ 'check' + i }>{ name }</label>
                    </div>
                ))}
            </div>

            {/* Table displaying model output */}
            <div className='col-span-1 md:col-span-2 w-full table-fixed border bg-white shadow-lg'>
                <p className='font-normal text-xl mt-5 mb-5'>Lift Model Output</p>
                { (Object.keys(overviewResults).length) ?
                    <div>
                        <table className="w-full text-xs md:text-base">
                            <thead>
                                <tr>
                                    <th className='pb-5 pt-5 w-4/12'>Survey<br />Question</th>
                                    <th className='pb-5 pt-5 w-2/12'>Avg<br />Lift</th>
                                    <th className='pb-5 pt-5 w-2/12'>Number<br />Lifted</th>
                                    <th className='pb-5 pt-5 w-2/12'>Lift<br />Prob</th>
                                    <th className='pb-5 pt-5 w-2/12'>P-<br />Value</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Object.keys(overviewResults).map((k) => (
                                    (!exclusions.includes(overviewResults[k].question)) ?
                                        <tr className='border-t' key={ k }>
                                            <td className='pb-5 pt-5 font-bold'>{
                                                overviewResults[k].question
                                            }</td>
                                            <td className={
                                                (Math.round(overviewResults[k].avg_lift * 1000) / 10 >= 0) ?
                                                    'pb-5 pt-5 text-good' : 'pb-5 pt-5 text-bad'
                                            }>{
                                                Math.round(overviewResults[k].avg_lift * 1000) / 10
                                            }pp</td>
                                            <td className={
                                                (Math.round(overviewResults[k].avg_lift * 1000) / 10 >= 0) ?
                                                    'pb-5 pt-5 text-good' : 'pb-5 pt-5 text-bad'
                                            }>{
                                                Math.round(overviewResults[k].avg_lift * size).toLocaleString()
                                            }</td>
                                            <td className='pb-5 pt-5'>{
                                                Math.round(overviewResults[k].lift_prob * 1000) / 10
                                            }%</td>
                                            <td className='pb-5 pt-5'>{
                                                Math.round(overviewResults[k].p_value * 100) / 100
                                            }</td>
                                        </tr>
                                    : []
                                ))}
                            </tbody>
                        </table>
                        <p className='font-normal italic text-sm mb-5 ml-5 mr-5 mt-3'>
                            "Number Lifted" estimates the number of people in the treatment
                            audience who changed attitudes due to media.
                            "Lift Prob" estimates the likelihood of positive lift,
                            accounting for survey sampling error. "P-Value" estimates the probability
                            of lift greater than or equal to the average lift due to random survey sampling.
                        </p>
                    </div>
                : <Loading /> }
            </div>

            {/* Display number of positive KPIs */}
            <div 
                className='col-span-1 w-full border bg-white 
                           shadow-lg flex flex-col items-center justify-center 
                           text-center p-10'
            >
                { (Object.keys(overviewResults).length) ?
                    <div
                        className='text-3xl font-bold'
                    >
                        <p className={ (ratioKPIs >= 0.5) ? 'text-good mb-5 mt-5' : 'text-bad mb-5 mt-5' }>
                            <span className='text-8xl'>{ positiveKPIs }</span>
                            <br />
                            out of { totalKPIs }
                        </p>
                    </div>
                :  // Display question marks for loading status
                    <Loading />
                }
                <p className='font-normal text-xl mb-5'>
                    Questions
                    { (Object.keys(overviewResults).length > 1) ? <br /> : ' ' }
                    with Positive Lift<br />
                    after Exposure
                    { (Object.keys(overviewResults).length > 1) ? <br /> : ' ' }
                    to Campaign
                </p>
            </div>

            {/* Margin of error chart */}
            <div
                className='col-span-1 md:col-span-3 w-full border bg-white shadow-lg text-center'
            >
                <p className='font-normal text-xl mt-5'>Average Lift with 80% Margin of Error Estimates</p>
                { (Object.keys(overviewResults).length) ?
                    <div>
                        <div className='plotly-chart-height mb-3'>
                            <div style={ { width: '100%', height: '100%' } } ref={ ref }>
                                <Plot
                                    data={[
                                        {
                                            x: liftKPIs,
                                            y: nameKPIs,
                                            type: 'bar',
                                            hoverinfo: 'skip',
                                            marker: { color: '#3dbaff' },
                                            orientation: 'h',
                                            error_x: {
                                                type: 'data',
                                                symmetric: false,
                                                array: lift90,
                                                arrayminus: lift10,
                                                color: '#E7EAED'
                                            } 
                                        },
                                    ]}
                                    layout={{ 
                                        width: width, 
                                        height: height,
                                        dragmode: false,
                                        paper_bgcolor: 'rgba(0, 0, 0, 0)',
                                        margin: {
                                            l: 225,
                                            r: 50,
                                            t: 50,
                                            b: 75
                                        },
                                        font: {
                                            family: "Open Sans",
                                            color: 'black',
                                            size: 16,
                                        },
                                        xaxis: {
                                            tickformat: ',.1%',
                                            range: [minVal, maxVal],
                                            title: 'Lift'
                                        }
                                    }}
                                    config={{
                                        displayModeBar: false,
                                    }}
                                />
                            </div>
                        </div>
                        <p className='font-normal italic text-sm m-5'>
                            "Average Lift" uses survey responses
                            from people exposed to the media (treatment)
                            and people not exposed (control) to quantify
                            the impact of media exposure. The "80% Margin of Error Estimates"
                            quantify uncertainty due to random survey sampling.
                            If the surveys were randomly administered again,
                            80% of the average lift estimates should fall within this range. 
                            Lift probabilities are indicated by the following symbols:
                            >67%: ^, >80%: *, >90%: **, >95%: ***.
                        </p>
                    </div>
                : <Loading /> }
            </div>

            {/* Treatment and control levels */}
            <div
                className='col-span-1 md:col-span-3 w-full border bg-white shadow-lg text-center'
            >
                <p className='font-normal text-xl mt-5'>Control and Treatment Levels</p>
                { (Object.keys(overviewResults).length) ? 
                    <div>
                        <div className='plotly-chart-height mb-3'>
                            <div style={{ width: '100%', height: '100%' }} ref={ref}>
                                <Plot
                                    data={ [barControlData, barTreatmentData] }
                                    layout={{ 
                                        width: width, 
                                        height: height,
                                        dragmode: false,
                                        paper_bgcolor: 'rgba(0, 0, 0, 0)',
                                        margin: {
                                            l: 100,
                                            r: 100,
                                            t: 50,
                                            b: 50
                                        },
                                        font: {
                                            family: "Open Sans",
                                            color: 'black',
                                            size: 16,
                                        },
                                        yaxis: {
                                            tickformat: ',.0%',
                                            title: 'Average Level<br> <br>'
                                        },
                                        legend: {
                                            orientation: 'v',
                                        }
                                    }}
                                    config={
                                        {
                                            displayModeBar: false,
                                        }
                                    }
                                />
                            </div>
                        </div>
                        <p className='font-normal italic text-sm m-5'>
                            Average treatment and control levels estimate the proportion of 
                            the treatment audience holding the specified view for each
                            survey question KPI after media exposure
                            and estimate the proportion had the treatment audience not been exposed.
                            Lift probabilities are indicated by the following symbols:
                            >67%: ^, >80%: *, >90%: **, >95%: ***.
                        </p>
                    </div>
                : <Loading /> }
            </div>
        </div>
    );
}

// Export for imports
export default Overview;
