// React
import React, {
    useState
} from 'react';  

// Get common components
import Body from '../common/Body.js';
import Button from '../common/Button.js';

// Variable name mapping creation
function Variable() {

    // File selection and messages
    const [selectedFile, setSelectedFile] = useState();
    const [isFileSelected, setIsFileSelected] = useState(false);
    const [message, setMessage] = useState(false);
    const [success, setSuccess] = useState(false);

    // Handle the form changes
    const handleChange = (e) => {
        setSelectedFile(e.target.files[0]);
        setIsFileSelected(true);
    };

    // Attempt the organization creation
    const attemptCreation = () => {
        
        // Only execute if a file is selected
        if (isFileSelected) {
            const formData = new FormData();
            formData.append('filename', 'TemplateFile');
            formData.append('file', selectedFile);

            // Send the request
            fetch('/data/variable_template_overwrite', {
                method: 'POST',
                body: formData

            // If the response is a failure, update that message
            }).then(res => {
                res.json().then(data => {
                    setMessage(data.message);
                });
                if (res.status === 200) {
                    setSuccess(true);
                };
            });
        };
    };

    // Return the layout for the group name creation
    return (
        <Body>
            <div className="
                w-9/12 md:w-4/12
                mb-5 md:mb-0
                grid grid-cols-1 md:gap-0 
                justify-items-center content-center"
            >
                <h1 className='
                    text-intrvl font-normal text-4xl 
                    md:text-3xl 
                    mt-3 mb-8
                    tracking-widest'
                >
                    VARIABLE NAME CREATION
                </h1>

                {/* Download */}
                <form
                    action={ '/data/variable_template_download' }
                    method='POST'
                    className='w-12/12'
                >
                    <input type='hidden' />
                    <Button type={ 'submit' } addClasses='w-9/12'>
                        <h2 className='text-lg font-bold text-center font-display mt-5 mb-5'>Download Template</h2>
                    </Button>
                </form>

                {/* Name */}
                <label className='mb-4 mt-9 font-display text-xl text-center'>Edited Template File</label>
                <input type="file" id="formFile" onChange={ handleChange } className="
                    mb-9
                    form-control
                    block
                    w-full
                    px-3
                    py-1.5
                    text-base
                    font-normal
                    text-gray-700
                    bg-white bg-clip-padding
                    border border-solid border-gray-300
                    rounded
                    transition
                    ease-in-out
                    m-0
                    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                />

                {/* Create */}
                <Button onClick={ attemptCreation } className='w-9/12'>
                    <h2 className='text-lg font-bold text-center font-display mt-5 mb-5'>
                        Upload<br/>Template
                    </h2>
                </Button>

                {/* Alerts */}
                { (message) ? 
                        <p className={
                            'italic mt-8 mb-5 ' + ((success) ? 'text-green-500' : 'text-red-500')
                        }>
                            { message }
                        </p>
                    : []
                }
            </div>
        </Body>
    );
}

// Export for imports
export default Variable;
