// React
import React from 'react';

// Images
import logo from '../images/intrvl_line_blue.svg';
import name from '../images/intrvl_name_gray.svg';

// Footer doesn't really do much, but it looks cohesive
export default function Footer() {

    // Return the layout for the footer
    return (
        <div className='bg-midnight w-full text-gray-300 pt-3 pb-3'>
            <div className='grid grid-cols-1 md:grid-cols-3 gap-5 md:gap-0 items-center px-2 max-w-7xl mx-auto sm:px-6 lg:px-8'>
                <p className='mb-0 px-3 py-0 md:py-2 rounded-md text-sm font-medium text-center md:text-left'>
                    © { new Date().getFullYear() } | all rights reserved
                </p>
                <div className="flex-shrink-0 flex justify-center hidden md:flex">
                    <img
                        className="hidden sm:block h-6 w-auto mr-4"
                        src={ logo }
                        alt="INTRVL Logo"
                    />
                    <img
                        className="block h-5 w-auto"
                        src={ name }
                        alt="INTRVL Logo Name"
                    />
                </div>
                <p className='mb-0 px-3 py-2 rounded-md text-sm font-medium text-center md:text-right hidden md:block'>
                    <a href="https://intrvl.us/" target="_blank" rel="noreferrer">homepage</a> | <a href="mailto:info@intrvl.us">contact</a>
                </p>
            </div>
        </div>
    )
}