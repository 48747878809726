// React
import React, {
    useState,
    useEffect
} from 'react';

// Common functions
import Common from '../common/Common.js';

// Loading
import Loading from '../loading/Loading.js';

// Color scale
import chroma from 'chroma-js';

// Sort only alphanumeric characters
function easySort(data) {
    data.sort(function(a, b) {
        var string1 = /[a-zA-Z0-9]+/.exec(a);
        var string2 = /[a-zA-Z0-9]+/.exec(b);
        if(string1 && string2) {
            return string1[0].localeCompare(string2[0]);
        } else {
            return a - b;
        };
    });
    return data;
};

// Display crosstabs for a campaign
function Crosstabs(props) {
    const id = props.id;

    // State for storing results from the API
    const [crosstabResults, setCrosstabResults] = useState(false);
    const [descriptions, setDescriptions] = useState({});
    const [rankings, setRankings] = useState(false);
    const [exclusions, setExclusions] = useState([]);

    // Update the exclusion list
    const updateExclusion = (event) => { 
        var value = event.target.value;
        if (exclusions.includes(value)) {
            setExclusions(exclusions.filter(x => x !== value));
        } else {
            setExclusions(current => [...current, value]);
        }
    };

    // State for storing user selections
    const [question, setQuestion] = useState(false);
    const [group1, setGroup1] = useState(false);
    const [group2, setGroup2] = useState(false);

    // Options are derived from the group selection
    const [group1Options, setGroup1Options] = useState(false);
    const [group2Options, setGroup2Options] = useState(false);

    // Ask the API for the crosstab data
    useEffect(() => {

        // Fetch the data
        if (id) {
            fetch('/data/crosstab_results', {
                method: 'post',
                body: JSON.stringify({
                'campaign-id': id
                }),
                headers: { 'Content-Type': 'application/json' }
            }).then(res => res.json()).then(data => {

                // Set the descriptions
                setDescriptions(data.mappings);

                // Set the first question alphabetically
                var questionSort = data.questions.sort();
                setQuestion(questionSort[0]);

                // Set the groups alphabetically (assume list is sorted by API)
                setGroup1(data.pairs[0][0]);
                setGroup2(data.pairs[0][1]);

                // Add the data to the results object
                setCrosstabResults(data);
            });
        };
    
    // Update when id changes
    }, [id]);

    // Ask the API for the ranking data
    useEffect(() => {

        // Fetch the data
        if (id) {
            fetch('/data/feature_rankings', {
                method: 'post',
                body: JSON.stringify({
                'campaign-id': id
                }),
                headers: { 'Content-Type': 'application/json' }
            }).then(res => res.json()).then(data => {

                // Add the data to the ranking object
                setRankings(data);
            });
        };
    
    // Update when id changes
    }, [id]);

    // Set the options for group 2 to match the group 1 selection
    useEffect(() => {
        var data2 = [];

        // Iterate across each pair to check if the group 1 selection is within it
        if (crosstabResults) {
            for (var i in crosstabResults.pairs) {
                var pair = crosstabResults.pairs[i]
                if (pair.includes(group1)) {
                    for (var ii in pair) {
                        if (group1 !== pair[ii]) {
                            data2.push(pair[ii]);
                        };
                    };
                };
            };
        };

        // Create the options for group2
        if (data2.length > 0) {
            setGroup2Options(data2);
        };
    
    // Update when id changes
    }, [group1, crosstabResults]);

    // Set the options for group 1 to match the group 1 selection
    useEffect(() => {
        var data1 = [];

        // Iterate across each pair to check if the group 2 selection is within it
        if (crosstabResults) {
            for (var i in crosstabResults.pairs) {
                var pair = crosstabResults.pairs[i]
                if (pair.includes(group2)) {
                    for (var ii in pair) {
                        if (group2 !== pair[ii]) {
                            data1.push(pair[ii]);
                        };
                    };
                };
            };
        };

        // Create the options for group2
        if (data1.length > 0) {
            setGroup1Options(data1);
        };
    
    // Update when id changes
    }, [group2, crosstabResults]);

    // Reset the question on form change
    const handleQuestion = (event) => { 
        var value = event.target.value;
        setQuestion(value);
    };

    // Reset the group 1 on form change
    const handleGroup1 = (event) => { 
        var value = event.target.value;
        setGroup1(value);
    };

    // Reset the group 2 on form change
    const handleGroup2 = (event) => { 
        var value = event.target.value;
        setGroup2(value);
    };

    // Get options for the question and group dropdowns
    var questionDropdown = Common.dropdownOptions(crosstabResults.questions);
    var group2Unique = [];
    var group1Unique = [];
    if ((group2Options.length) && (group1Options.length)) {
        group2Unique = [...new Set(group2Options)]
        group1Unique = [...new Set(group1Options)]
    }
    var group2Dropdown = Common.dropdownOptions(group2Unique, question, rankings);
    var group1Dropdown = Common.dropdownOptions(group1Unique, question, rankings);

    // Table data is an array of objects
    var tableData = {};
    var group1Names = [];
    var group2Names = [];
    var scaleValues = [];

    // Iterate over the results if they exist and select the correct pairs for the table
    if (crosstabResults && group1 && group2 && question) {
        for (var i in crosstabResults.output[question]) {
            var row = crosstabResults.output[question][i];
            var pair = row.pair;
            if ((pair.includes(group1)) && (pair.includes(group2))) {

                // Check if in exclusion array before proceeding with the pairs
                var key1 = row[group1];
                var key2 = row[group2];
                if (!exclusions.includes(key1)) {
                    if (!(key1 in tableData)) {
                        tableData[key1] = {};
                    };

                    // Check for second problem
                    if (!exclusions.includes(key2)) {

                        // Push to array
                        scaleValues.push(row.avg_lift);

                        // Update the table
                        tableData[key1][key2] = Math.round(row.avg_lift * 10000) / 100 + 'pp ';

                        // Add the symbols for lift probability
                        if (row.lift_prob > 0.95) {
                            tableData[key1][key2] += '***';
                        } else if (row.lift_prob > 0.9) {
                            tableData[key1][key2] += '**';
                        } else if (row.lift_prob > 0.8) {
                            tableData[key1][key2] += '*';
                        } else if (row.lift_prob > 0.67) {
                            tableData[key1][key2] += '^';
                        }
                    }
                }

                // Put the names in the array
                if (group1Names.includes(key1) === false) {
                    group1Names.push(key1);
                }
                if (group2Names.includes(key2) === false) {
                    group2Names.push(key2);
                }
            };
        };

        // Create the colorscale
        var maxVal = Math.max.apply(Math, scaleValues);
        var minVal = Math.min.apply(Math, scaleValues);
        if (minVal >= 0) {
            var scale = chroma
                .scale(['white', '#73AB84'])
                .mode('lab')
                .domain([0, maxVal]);
        } else if (maxVal <= 0) {
            scale = chroma
                .scale(['#EB5E28', 'white'])
                .mode('lab')
                .domain([minVal, 0]);
        } else {
            var absVal = maxVal;
            if (Math.abs(minVal) > maxVal) {
                absVal = Math.abs(minVal);
            };
            scale = chroma
                .scale(['#EB5E28', 'white', '#73AB84'])
                .mode('lab')
                .domain([(0 - absVal), 0, absVal]);
        };
    };

    // Sort the group values
    group1Names = easySort(group1Names);
    group2Names = easySort(group2Names);
    var groupAllNames = group1Names.concat(group2Names);

    console.log(tableData);

    console.log()

    // Number pattern for the regex
    var numberPattern = /\d+|-/g;

    // Return the layout for the crosstabs view
    return (
        <div>
            <div className="
                grid
                grid-cols-1 md:grid-cols-4 
                gap-5
                justify-items-center
                content-center
            ">

                {/* Map the questions */}
                <div className='
                    col-span-1 md:col-span-4
                    flex
                    w-full
                    border
                    bg-white
                    shadow-lg
                    text-center
                '>
                    <p className='w-4/12 text-xl mt-5 mb-5 p-2'>
                        Question
                    </p>
                    <select 
                        className="
                            minimal
                            form-select
                            border
                            rounded-lg
                            w-8/12
                            mb-5 mt-5 mr-5
                            bg-white
                            cursor-pointer
                            text-md
                            text-center
                            p-2
                        "
                        onChange={ (event) => handleQuestion(event) }
                    >
                        { questionDropdown }
                    </select>
                </div>

                {/* Map the groups */}
                <div className='
                    col-span-1 md:col-span-2
                    border
                    bg-white
                    shadow-lg
                    text-center
                    w-full
                '>
                    <div className='flex w-full'>
                        <p className='w-4/12 text-xl mt-5 mb-5 p-2'>
                            Group 1
                        </p>
                        <select 
                            className="
                                minimal
                                form-select
                                border
                                rounded-lg
                                w-8/12
                                mb-5 mt-5 mr-5
                                bg-white
                                cursor-pointer
                                text-md
                                text-center
                                p-2
                            "
                            onChange={ (event) => handleGroup1(event) }
                            value={ group1 }
                        >
                            { group1Dropdown }
                        </select>
                    </div>
                    { ((group1 in descriptions) && (rankings)) ?
                        <p className='w-full italic text-sm mb-5 text-center pr-5 pl-5'>
                            { descriptions[group1].groups_desc }.
                            Importance Rank: { rankings[question][group1] }.
                        </p>  
                    :
                        <div />        
                    }
                </div>

                {/* Map the second groups */}
                <div className='
                    col-span-1 md:col-span-2
                    border
                    bg-white
                    shadow-lg
                    text-center
                    w-full
                '>
                    <div className='flex w-full'>
                        <p className='w-4/12 text-xl mt-5 mb-5 p-2'>
                            Group 2
                        </p>
                        <select 
                            className="
                                minimal
                                form-select
                                border
                                rounded-lg
                                w-8/12
                                mb-5 mt-5 mr-5
                                bg-white
                                cursor-pointer
                                text-md
                                text-center
                                p-2
                            "
                            onChange={ (event) => handleGroup2(event) }
                            value={ group2 }
                        >
                            { group2Dropdown }
                        </select>
                    </div>
                    { ((group2 in descriptions) && (rankings)) ?
                        <p className='w-full italic text-sm mb-5 text-center pr-5 pl-5'>
                            { descriptions[group2].groups_desc }.
                            Importance Rank: { rankings[question][group2] }.
                        </p>  
                    :
                        <div />        
                    }
                </div>

                {/* Allow for the removal of values */}
                <div className='
                    col-span-1 md:col-span-4
                    flex 
                    w-full
                    border
                    bg-white
                    shadow-lg
                    text-center'
                >
                    <p className='w-full text-xl mt-5 mb-5 p-2'>
                        Displayed Data
                    </p>
                    { groupAllNames.map((name, i) => (
                        <div key={ i } className='w-3/12 flex flex-col justify-center text-center'>
                            <input 
                                type='checkbox'
                                onChange={ updateExclusion } 
                                value={ name } 
                                checked={ (exclusions.includes(name)) ? false : true }
                                id={ 'check' + i }
                            >
                            </input>
                            <label htmlFor={ 'check' + i }>{ Common.groupNameClean(name) }</label>
                        </div>
                    ))}
                </div>

                {/* Heatmap of the results */}
                <div className='
                    col-span-1 md:col-span-4
                    w-full
                    table-fixed
                    border
                    bg-white
                    shadow-lg
                '>
                    <p className='font-normal text-xl mt-5 mb-5'>
                        Crosstab of Average Lift
                        for { group1 ? group1 : 'Group' } by { group2 ? group2 : 'Group' }
                    </p>
                    { ((Object.keys(tableData).length > 0) && ([...new Set(Array.from(Object.values(tableData), x => Object.keys(x)).flat(1))].length > 0)) ?
                        <div className='ml-4 mr-5'>
                            <table className="w-full text-xs md:text-base table-fixed">
                                <thead>
                                    <tr>
                                        <th colSpan={ 2 } className='pb-5 pt-5'></th>
                                        <th 
                                            colSpan={ easySort([...new Set(Array.from(Object.values(tableData), x => Object.keys(x)).flat(1))]).length }
                                            className='pb-5 pt-5'
                                        >
                                            { Common.groupNameClean(group2) }
                                        </th>
                                    </tr>
                                    <tr>
                                        <th colSpan={ 2 } className='pb-5 pt-5 w-2/12'></th>
                                        { easySort([...new Set(Array.from(Object.values(tableData), x => Object.keys(x)).flat(1))]).map((x, i) => (
                                            <th key={ i }className='pb-5 pt-5 w-2/12 border'>
                                                { Common.groupNameClean(x) }
                                            </th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                <tr className='font-bold'>
                                    <td 
                                        rowSpan={ easySort(Object.keys(tableData)).length + 1 }
                                        colSpan={ 1 }
                                        className='break-words'
                                    >
                                        { Common.groupNameClean(group1) }
                                    </td>
                                </tr>
                                { easySort(Object.keys(tableData)).map((vertical, i) => (
                                    <tr key={ i }>
                                        <td className='pb-5 pt-5 font-bold border'>
                                            { Common.groupNameClean(vertical) }
                                        </td>
                                        { easySort([...new Set(Array.from(Object.values(tableData), x => Object.keys(x)).flat(1))]).map((horizontal, ii) => (
                                            <td 
                                                className='pb-5 pt-5 border' 
                                                key={ ii }
                                                style={{ 
                                                    backgroundColor: scale(
                                                        parseFloat(tableData[vertical][horizontal].match(numberPattern).join('.')) / 100.0
                                                    ).hex() 
                                                }}
                                            >
                                                { tableData[vertical][horizontal] }
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                            <p className='font-normal italic text-sm mb-5 mt-8'>
                                Average lift is calculated using survey responses
                                from people exposed to the media
                                (treatment) and people not exposed (control).
                                This crosstab displays average lift
                                data for selected groups,
                                which are listed in bold on the table header.
                                Lift probabilities are indicated by the following symbols:
                                >67%: ^, >80%: *, >90%: **, >95%: ***.
                            </p>
                        </div>
                    : <Loading /> }
                </div>
            </div>
        </div>
    );
}

// Export for imports
export default Crosstabs;
