// React
import React, {
    useState,
    useEffect
  } from 'react';

// Loading
import Loading from '../loading/Loading.js';

// Common functions
import Common from '../common/Common.js';

// Plotly
import Plot from 'react-plotly.js';

// Color scale
import chroma from 'chroma-js';

// Resizing
import useResizeObserver from 'use-resize-observer';

// Display important variables for a campaign
function Importance(props) {
    const id = props.id;

    // State for storing results from the API
    const [groupResults, setGroupResults] = useState({});
    const [rankings, setRankings] = useState(false);

    // State for storing using selections
    const [question, setQuestion] = useState(false);

    // Get the resizing info
    const { ref, width = 1, height = 1 } = useResizeObserver();

    // Ask the API for the group data
    useEffect(() => {

        // Fetch the data
        if (id) {
            fetch('/data/group_results', {
                method: 'post',
                body: JSON.stringify({
                'campaign-id': id
                }),
                headers: { 'Content-Type': 'application/json' }
            }).then(res => res.json()).then(data => {

                // Set the first question alphabetically
                var questionSort = data.questions.sort();
                setQuestion(questionSort[0]);

                // Add the data to the results object
                setGroupResults(data);
            });
        };
    
    // Update when campaign id changes
    }, [id]);

    // Ask the API for the ranking data for the importance of groups
    useEffect(() => {

        // Fetch the data
        if (id) {
            fetch('/data/feature_rankings', {
                method: 'post',
                body: JSON.stringify({
                'campaign-id': id
                }),
                headers: { 'Content-Type': 'application/json' }
            }).then(res => res.json()).then(data => {

                // Add the data to the ranking object
                setRankings(data);
            });
        };
    
    // Update when id changes
    }, [id]);

    // Reset the question on form change
    const handleQuestion = (event) => { 
        var value = event.target.value;
        setQuestion(value);
    };

    // Get options for the question and group dropdowns
    var questionOptions = Common.dropdownOptions(groupResults.questions);

    // Iterate to load the table array with objects for rows
    var tableArray = [];
    if ((Object.keys(groupResults).length > 0) & (Object.keys(rankings).length > 0)) {
        var results = groupResults.output[question];
        
        // Put each key into the table object
        for (var key in results) {
            var minLift = Math.min.apply(Math, results[key].avg_lift);
            var maxLift = Math.max.apply(Math, results[key].avg_lift);
            tableArray.push({
                feature: key,
                rank: rankings[question][key],
                min: minLift,
                minGroup: results[key].group[results[key].avg_lift.indexOf(minLift)],
                max: maxLift,
                maxGroup: results[key].group[results[key].avg_lift.indexOf(maxLift)],
                range: Math.abs(minLift - maxLift),
                relative: Math.max.apply(Math, results[key].lift_predictive_value),
            });
        };
    };

    // Sort by rank
    tableArray.sort((a, b) => 
        (a.relative < b.relative) ? 1 
        : ((b.relative < a.relative) ? -1 : 0)
    );

    // Make the colorscale for the visualization
    var maxVal = Math.max.apply(Math, tableArray.map(function(o) { return o.relative; }));
    var minVal = Math.min.apply(Math, tableArray.map(function(o) { return o.relative; }));
    var scale = chroma
        .scale(['#E7EAED', '#73AB84'])
        .mode('lab')
        .domain([100, 0]);

    // Iterate to create the data for the horizontal bar chart
    var variableNames = [];
    var variableValues = [];
    var variableColors = [];
    if ((Object.keys(groupResults).length > 0) & (Object.keys(rankings).length > 0)) {
        for (var i in tableArray) {

            // Normalize the value on 100 scale
            var normalized = (tableArray[i].relative - minVal) / (maxVal - minVal) * 100;

            // Show /something/ on the graph
            if (normalized === 0) {
                normalized = 0.5;
            };

            // Add to the arrays for Plotly
            variableNames.push('<b>' + tableArray[i].feature + ' </b>');
            variableValues.push(normalized);
            variableColors.push(scale(normalized).hex())
        };
    };

    // Reverse the order for Plotly
    variableNames.reverse();
    variableValues.reverse();

    // Return the layout for the groups view
    return (
        <div>
            <div className="
                grid 
                grid-cols-1 md:grid-cols-6 
                gap-5 
                justify-items-center 
                content-center
            ">

                {/* Map the questions */}
                <div className='
                    col-span-1 md:col-span-6
                    flex
                    w-full
                    border
                    bg-white
                    shadow-lg
                    text-center
                '>
                    <p className='w-4/12 text-xl mt-5 mb-5 p-2'>
                        Question
                    </p>
                    <select 
                        className="
                            minimal 
                            form-select
                            border 
                            w-8/12
                            mb-5 mt-5 mr-5
                            bg-white
                            rounded-lg
                            cursor-pointer
                            text-md
                            text-center
                            p-2
                        "
                        onChange={ (event) => handleQuestion(event) }
                    >
                        { questionOptions }
                    </select>
                </div>

                {/* Relative importance chart */}
                <div
                    className='
                        col-span-1 md:col-span-6
                        w-full
                        border
                        bg-white
                        shadow-lg
                        text-center
                '>
                    <p className='font-normal text-xl mt-5'>
                        Relative Importance of Audience Features
                    </p>
                    { ((variableValues.length) && (variableNames.length)) ?
                        <div>
                            <div className='plotly-large-height mb-3'>
                                <div style={ { width: '100%', height: '100%' } } ref={ ref }>
                                    <Plot
                                        data={[
                                            {
                                                x: variableValues,
                                                y: variableNames,
                                                type: 'bar',
                                                hoverinfo: 'skip',
                                                marker: { color: variableColors },
                                                orientation: 'h',
                                            },
                                        ]}
                                        layout={{ 
                                            width: width, 
                                            height: height,
                                            dragmode: false,
                                            paper_bgcolor: 'rgba(0, 0, 0, 0)',
                                            margin: {
                                                l: 250,
                                                r: 50,
                                                t: 50,
                                                b: 75
                                            },
                                            font: {
                                                family: "Open Sans",
                                                color: 'black',
                                                size: 16,
                                            },
                                            xaxis: {
                                                range: [0, 100],
                                                title: 'Importance (Normalized)'
                                            }
                                        }}
                                        config={{
                                            displayModeBar: false,
                                        }}
                                    />
                                </div>
                            </div>
                            <p className='font-normal italic text-sm m-5'>
                                "Relative Importance" assesses the most
                                and least important explanatory variables
                                for the question <i>{ question }</i>.
                                Higher values indicate more importance within INTRVL's
                                lift model, while lower values indicate a less important
                                relationship to backlash and lift. The score is normalized
                                to fall between 0 and 100.
                            </p>
                        </div>
                    : <Loading /> }
                </div>

                {/* Table displaying ranked importance of variables */}
                <div className='
                    col-span-1 md:col-span-6 
                    w-full 
                    table-fixed 
                    border 
                    bg-white 
                    shadow-lg'
                >
                    <p className='font-normal text-xl mt-5 mb-5'>
                        Ranked Importance of Audience Features
                    </p>
                    { (tableArray.length) ?
                        <div>
                            <table className="w-full text-xs md:text-base">
                                <thead>
                                    <tr>
                                        <th className='pb-5 pt-5 w-4/12'>Feature<br />Name</th>
                                        <th className='pb-5 pt-5 w-2/12'>Ranked<br />Importance</th>
                                        <th className='pb-5 pt-5 w-2/12'>Min<br />Lift</th>
                                        <th className='pb-5 pt-5 w-2/12'>Max<br />Lift</th>
                                        <th className='pb-5 pt-5 w-2/12'>Lift<br />Range</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    { tableArray.map((row, i) => (
                                        <tr className='border-t' key={ i }>
                                            <td className='pb-7 pt-7 font-bold'>{
                                                row.feature
                                            }</td>
                                            <td className='pb-7 pt-7 font-bold'>{
                                                i + 1
                                            }</td>
                                            <td>
                                                <span className={
                                                    (Math.round(row.min * 1000) / 10 >= 0) ?
                                                    'pb-7 pt-7 text-good' : 'pb-7 pt-7 text-bad'
                                                }>{
                                                    Math.round(row.min * 1000) / 10
                                                }%</span><br /><span className='italic text-sm'>
                                                { Common.groupNameClean(row.minGroup) }</span>
                                            </td>
                                            <td>
                                                <span className={
                                                    (Math.round(row.max * 1000) / 10 >= 0) ?
                                                    'pb-7 pt-7 text-good' : 'pb-7 pt-7 text-bad'
                                                }>{
                                                    Math.round(row.max * 1000) / 10
                                                }%</span><br /><span className='italic text-sm'>
                                                { Common.groupNameClean(row.maxGroup) }</span>
                                            </td>
                                            <td className='pb-7 pt-7'>{
                                                Math.round(row.range * 1000) / 10
                                            }%</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <p className='font-normal italic text-sm mb-5 ml-5 mr-5 mt-3'>
                                "Ranked Importance" assesses the most
                                and least important explanatory variables
                                for the question <i>{ question }</i>.
                                "Lift Range" displays the difference between the
                                highest and lowest groups within the variable.
                                For more important variables, this range will often (but not always)
                                be larger, depending on the variable's relationship
                                to backlash or lift.
                            </p>
                        </div>
                    : <Loading /> }
                </div>
            </div>
        </div>
    );
}

// Export for imports
export default Importance;
