// React
import React from "react";
import { Switch, Route } from "react-router-dom";

// Get the user context for the application
import AuthProvider from "./auth/Provider.js";

// Main application pages
import Home from "./home/Home.js";
import AllCampaigns from "./home/AllCampaigns";
import Results from "./results/Results.js";
import NotFound from "./404/NotFound.js";

// Authentication and admin functions
import Campaign from "./user/Campaign.js";
import Group from "./user/Group.js";
import Login from "./user/Login.js";
import Logout from "./user/Logout.js";
import Organization from "./user/Organization.js";
import Password from "./user/Password.js";
import Settings from "./user/Settings.js";
import User from "./user/User.js";
import Variable from "./user/Variable.js";
import Delete from "./user/Delete.js";

// Route to the components
function App() {
  return (
    <main>
      <AuthProvider>
        <Switch>
          {/* Main application routes */}
          <Route path="/" component={Home} exact />
          <Route path="/index" component={Home} exact />
          <Route path="/all_campaigns" component={AllCampaigns} exact />
          <Route path="/results/:id" component={Results} exact />

          {/* Authentication and admin functions */}
          <Route path="/user/campaign" component={Campaign} exact />
          <Route path="/user/group_name" component={Group} exact />
          <Route path="/user/login" component={Login} exact />
          <Route path="/user/logout" component={Logout} exact />
          <Route path="/user/organization" component={Organization} exact />
          <Route path="/user/password" component={Password} exact />
          <Route path="/user/settings" component={Settings} exact />
          <Route path="/user/user" component={User} exact />
          <Route path="/user/variable_name" component={Variable} exact />
          <Route path="/user/delete" component={Delete} exact />

          {/* 404 */}
          <Route component={NotFound} />
        </Switch>
      </AuthProvider>
    </main>
  );
}

// Export the application for import in index
export default App;
