/* Tailwind Navbar */
import React, {
    Fragment,
    useContext
  } from 'react';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import { UserCircleIcon, MenuIcon, XIcon } from '@heroicons/react/outline';

// Get the authentication context
import authContext from '../auth/Context.js';

// Images
import logo from '../images/intrvl_line_blue.svg';
import name from '../images/intrvl_name_gray.svg';

// Provide routes for the different pages on the navbar
const navigation = [
    { name: 'Confidence', href: '/', current: false },
    { name: 'All Campaigns', href: '/all_campaigns', current: false }
];

// Provide the class names
function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
};

// Navar will be present on all pages
export default function Nav() {

    // Get user information from the context
    const authContent = useContext(authContext);

    return (
        <Disclosure as="nav" className="bg-midnight fixed w-full font-light z-50">
            {({ open }) => (
                <>
                    <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
                        <div className="relative flex items-center justify-between h-16">
                            <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                                {/* Mobile menu button*/}
                                <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                                    <span className="sr-only">Open Main Menu</span>
                                    { open ? (
                                        <XIcon className="block h-6 w-6" aria-hidden="true" />
                                    ) : (
                                        <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                                    ) }
                                </Disclosure.Button>
                            </div>
                            <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                                <div className="flex-shrink-0 flex items-center">
                                    <img
                                        className="hidden sm:block h-6 w-auto mr-4"
                                        src={ logo }
                                        alt="INTRVL Logo"
                                    />
                                    <img
                                        className="block h-5 w-auto"
                                        src={ name }
                                        alt="INTRVL Logo"
                                    />
                                </div>
                                <div className="hidden sm:block sm:ml-6">
                                    <div className="flex space-x-4">
                                        { navigation.map((item) => (
                                            ((authContent) || (item.name === 'Magnify')) ? 
                                                <a
                                                    key={ item.name }
                                                    href={ item.href }
                                                    className={ classNames(
                                                        item.current ? 'bg-gray-900 text-gray-300' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                                                        'px-3 py-2 rounded-md text-sm font-medium'
                                                    ) }
                                                    aria-current={ item.current ? 'page' : undefined }
                                                >
                                                    { item.name }
                                                </a>
                                            : []
                                        )) }
                                    </div>
                                </div>
                            </div>
                            <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">

                                {/* Profile dropdown */}
                                <Menu as="div" className="ml-3 relative">
                                    <div>
                                        <Menu.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                                            <span className="sr-only">Open User Menu</span>
                                            <UserCircleIcon className="block h-6 w-6" aria-hidden="true" />
                                        </Menu.Button>
                                    </div>
                                    <Transition
                                        as={ Fragment }
                                        enter="transition ease-out duration-100"
                                        enterFrom="transform opacity-0 scale-95"
                                        enterTo="transform opacity-100 scale-100"
                                        leave="transition ease-in duration-75"
                                        leaveFrom="transform opacity-100 scale-100"
                                        leaveTo="transform opacity-0 scale-95"
                                    >
                                        <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                            { (authContent) ? 
                                                <div>
                                                <Menu.Item>
                                                    { ({ active }) => (
                                                        <a
                                                            href={ (authContent.role) ? '/user/settings' : '/user/password' } 
                                                            className={ classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700') }
                                                        >
                                                            { (authContent.role) ? 'Admin Settings' : 'Password Settings' } 
                                                        </a>
                                                    ) }
                                                </Menu.Item>
                                                <Menu.Item>
                                                    { ({ active }) => (
                                                        <a
                                                            href="/user/logout"
                                                            className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                                                        >
                                                            Sign Out
                                                        </a>
                                                    ) }
                                                </Menu.Item>
                                                </div>
                                            : 
                                                <Menu.Item>
                                                    { ({ active }) => (
                                                        <a
                                                            href="/"
                                                            className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                                                        >
                                                            Login
                                                        </a>
                                                    ) }
                                                </Menu.Item>
                                            }
                                        </Menu.Items>
                                    </Transition>
                                </Menu>
                            </div>
                        </div>
                    </div>
                    <Disclosure.Panel className="sm:hidden">
                        <div className="px-2 pt-2 pb-3 space-y-1">
                            { navigation.map((item) => (
                                <a
                                    key={ item.name }
                                    href={ item.href }
                                    className={ classNames(
                                        item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                                        'block px-3 py-2 rounded-md text-base font-medium'
                                    ) }
                                    aria-current={ item.current ? 'page' : undefined }
                                >
                                    { item.name }
                                </a>
                            )) }
                        </div>
                    </Disclosure.Panel>
                </>
            )}
        </Disclosure>
    )
}