// React
import React, {
  useState,
  useEffect
} from 'react';

// Get parameters from the route
import { 
  useParams,
  useHistory
} from 'react-router-dom'

// Get common components
import Nav from '../nav/Nav.js';
import Footer from '../nav/Footer.js';
import Button from '../common/Button.js';

// Get results components
import Overview from './Overview.js';
import Importance from './Importance.js';
import Groups from './Groups.js';
import Crosstabs from './Crosstabs.js';

// Display results for a campaign
function Results() {

  // Get the id from the route
  const { id } = useParams();

  // Use to navigate to 404 when the data is not available
  let history = useHistory();

  // Tabs for displaying results
  const tabs = [
    'Overview',
    'Importance',
    'Groups',
    'Crosstabs'
  ];
  const [selectedTab, setSelectedTab] = useState('Overview');
  const tabDownloads = {
    Overview: '/data/topline_download',
    Groups: '/data/group_download',
    Crosstabs: '/data/crosstab_download',
    Importance: '/data/importance_download'
  }

  // State for holding all of the relevant campaign details
  const [campaignDetails, setCampaignDetails] = useState({
    name: 'Loading...',
    created: 'Loading...',
    bq_table: false,
    size: 0
  });

  // Change the tab we are viewing
  const changeTab = (k) => {
    setSelectedTab(k);
  };

  // Ask the API for the campaign details (name, date, etc.)
  useEffect(() => {

    // Fetch the data if id exists
    if (id) {
      fetch('/data/campaign_details', {
        method: 'post',
        body: JSON.stringify({
          'campaign-id': id
        }),
        headers: { 
          'Content-Type': 'application/json' 
        }
      }).then(res => res.json()).then(data => {
        setCampaignDetails(data);
      }).catch(e => {
        history.push('/404');
      });
    };

  // Update when id changes
  }, [history, id]);

  // Return the layout for the results
  return (
    <div>
      <Nav />
      <div className="pt-24 pb-10 flex flex-col items-center text-center min-h-screen">

        {/* List the campaign details */}
        <h1 className="
          w-10/12
          text-intrvl
          font-normal
          text-2xl md:text-3xl
          tracking-widest
          mb-2
          uppercase
        ">
          { campaignDetails.name }
        </h1>
        <p className='w-10/12 italic text-md mb-5'>
          Results Delivered: { campaignDetails.created }
        </p>

        {/* Show tabs for selecting the view */}
        <div className='w-10/12 mb-10'>
          <div className="
            grid
            grid-cols-1 md:grid-cols-4
            gap-5 md:gap-5
            justify-items-center
            content-center
          ">
            {tabs.map((k) => (
              <div
                key={ k }
                onClick={ () => changeTab(k) }
                className={ (k === selectedTab) ?
                  "w-full border shadow-lg bg-intrvl text-white cursor-pointer rounded-lg"
                  : "w-full border shadow-lg bg-gray-300 bg-opacity-50 cursor-pointer rounded-lg"
                }
              >
                <h2 className='text-xl font-bold text-center mt-2 mb-2 capitalize'>{k}</h2>
              </div>
            ))}
          </div>
        </div>

        {/* Display the selected tab */}
        <div className='w-10/12'>
          { (selectedTab === 'Overview') ?
            <Overview 
                id={ id }
                size={ campaignDetails.size }
            />
            : false }
          { (selectedTab === 'Importance') ?
            <Importance 
                id={ id }
                size={ campaignDetails.size }
            />
            : false }
          { (selectedTab === 'Groups') ?
            <Groups 
                id={ id }
                size={ campaignDetails.size }
            />
            : false }
          { (selectedTab === 'Crosstabs') ?
            <Crosstabs 
                id={ id }
                size={ campaignDetails.size }
            />
            : false }
        </div>

        {/* Download the results on the screen */}
        <div className='w-10/12 mt-10 flex justify-center'>
          <form
            action={ tabDownloads[selectedTab] }
            method='POST'
            className='w-6/12'
          >
            <input type='hidden' id='campaign-id' name='campaign-id' value={ id } />
            <Button type={ 'submit' } addClasses='w-9/12'>
              <h2 className='text-xl font-bold text-center p-3'>Download Data</h2>
            </Button>
          </form>
        </div>
      </div>

      {/* Display the footer */}
      <Footer />
    </div>
  );
}

// Export for imports
export default Results;
