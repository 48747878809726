// React
import React, {
    useContext,
    useEffect,
    useState
} from 'react';  

// Get common components
import Body from '../common/Body.js';
import Button from '../common/Button.js';

// Import the context
import authContext from '../auth/Context.js';

// Reroute on password update
import { useHistory } from 'react-router-dom';

// Password Reset
function Password() {
    const history = useHistory();

    // Send the password form for validation
    const [passwordForm, setPasswordForm] = useState({
        password: false,
        new: false,
        confirm: false,
        user: ''
    });
    const [failed, setFailed] = useState(false);
    const [success, setSuccess] = useState(false);

    // Get user information from the context
    const authContent = useContext(authContext);

    // Handle the form changes
    const handleChange = (e) => {
        var id = e.target.id;
        var value = e.target.value;
        var newData = {...passwordForm};
        newData[id] = value
        setPasswordForm(newData);
    };

    // Attempt the password reset
    const attemptReset = () => {
        
        // Authenticate with the API
        fetch('/auth/user_password', {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(passwordForm)

        // If the response is a failure, update that message
        }).then(res => {
            res.json().then(data => {
                if (res.status === 200) {
                    setFailed(data.message);

                    // Navigate to home when first time user
                    if (authContent.logins === 1) {
                        setSuccess(true);
                    }
                
                // Tell the user why it failed
                } else {
                    setFailed(data.message);
                }
            });
        });
    };

    // On success for first time users, go to index
    useEffect(() => {
        if (success) {

            // Add one to the logins
            fetch('/auth/user_confirm', {
                method: 'post',
                headers: { 'Content-Type': 'application/json' }

            // Go to index
            }).then(res => {
                history.push('/index');
                history.go();
            });
        };
    }, [history, success]);

    // Return the layout for the login
    return (
        <Body>
            <div className="
                w-9/12 md:w-4/12
                mb-5 md:mb-0
                grid grid-cols-1 md:gap-0 
                justify-items-center content-center"
            >
                <h1 className='
                    text-intrvl font-normal text-4xl 
                    md:text-3xl 
                    mt-3 mb-2
                    uppercase
                    tracking-widest'
                >
                    PASSWORD RESET
                </h1>
                <p className="italic mt-0 mb-6">
                    { (authContent.logins === 1) ? 
                        'This is your first visit. Please change your password to something only you know.'
                    : '' } 
                </p>

                {/* User if Admin */}
                { ((authContent.role) && (authContent.logins !== 1)) ?
                    <div className='w-full'>
                        <label className='mb-4 font-display text-xl text-center'>User</label>
                        <input 
                            onChange={ (e) => handleChange(e) }
                            type="text" 
                            id="user" 
                            name="user" 
                            className="border-2 rounded-lg border-midnight w-full text-center p-2 mb-8"
                        />
                    </div>
                : [] }

                {/* Current Password */}
                <label className='mb-4 font-display text-xl text-center'>Current Password</label>
                <input 
                    onChange={ (e) => handleChange(e) }
                    type="password" 
                    id="password" 
                    name="password" 
                    className="border-2 rounded-lg border-midnight w-full text-center p-2 mb-8"
                />

                {/* New Password */}
                <label className='mb-4 font-display text-xl text-center'>New Password</label>
                <input 
                    onChange={ (e) => handleChange(e) }
                    type="password" 
                    id="new" 
                    name="new" 
                    className="border-2 rounded-lg border-midnight w-full text-center p-2 mb-8"
                />

                {/* Confirm Password */}
                <label className='mb-4 font-display text-xl text-center'>Confirm Password</label>
                <input 
                    onChange={ (e) => handleChange(e) }
                    type="password" 
                    id="confirm" 
                    name="confirm" 
                    className="border-2 rounded-lg border-midnight w-full text-center p-2 mb-8"
                />

                {/* Reset */}
                <Button onClick={ attemptReset }>
                    <h2 className='text-lg font-bold font-display mt-5 mb-5'>
                        Reset
                    </h2>
                </Button>

                {/* Alerts */}
                { (failed) ? 
                        <p className='text-red-500 mt-8 italic'>
                            { failed }
                        </p>
                    : []
                }
            </div>
        </Body>
    );
}

// Export for imports
export default Password;
