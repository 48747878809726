// React
import React, {
    useEffect,
    useState
} from 'react';  

// Get common components
import Body from '../common/Body.js';
import Button from '../common/Button.js';

// User creation
function User() {

    // Send the user form for validation
    const [userForm, setUserForm] = useState({
        phone: false,
        email: false,
        name: false,
        role: 0,
        organization: 1
    });
    const [message, setMessage] = useState(false);
    const [success, setSuccess] = useState(false);
    const [orgOptions, setOrgOptions] = useState(false);

    // Handle the form changes
    const handleChange = (e) => {
        var id = e.target.id;
        var value = e.target.value;
        var newData = {...userForm};
        newData[id] = value
        setUserForm(newData);
    };

    // Attempt the user creation
    const attemptCreation = () => {
        
        // Authenticate with the API
        fetch('/auth/user_creation', {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(userForm)

        // If the response is a failure, update that message
        }).then(res => {
            res.json().then(data => {
                setMessage(data.message);
            });
            if (res.status === 200) {
                setSuccess(true);
            };
        });
    };

    // On page load, update the available organizations
    useEffect(() => {
        fetch('/auth/user_creation', {
            method: 'get',
            headers: { 'Content-Type': 'application/json' },

        // If the response is a failure, update that message
        }).then(res => {
            res.json().then(data => {
                setOrgOptions(data);
            });
        });
    }, []);

    // Construct the JSX for those org options
    var orgJSX = [];
    if (orgOptions) {
        for (var key in orgOptions) {
            orgJSX.push(
                <option value={ key } key={ key }>
                    { orgOptions[key] }
                </option>
            );
        };
    };

    // Return the layout for the user creation
    return (
        <Body>
            <div className="
                w-9/12 md:w-4/12
                mb-5 md:mb-0
                grid grid-cols-1 md:gap-0 
                justify-items-center content-center"
            >
                <h1 className='
                    text-intrvl font-normal text-4xl 
                    md:text-3xl 
                    mt-3 mb-8
                    tracking-widest'
                >
                    USER CREATION
                </h1>

                {/* Email */}
                <label className='mb-4 font-display text-xl text-center'>Email</label>
                <input 
                    onChange={ (e) => handleChange(e) }
                    type="text" 
                    id="email" 
                    name="email" 
                    className="border-2 rounded-lg border-midnight w-full text-center p-2 mb-8"
                />

                {/* Name */}
                <label className='mb-4 font-display text-xl text-center'>Name</label>
                <input 
                    onChange={ (e) => handleChange(e) }
                    type="text" 
                    id="name" 
                    name="name" 
                    className="border-2 rounded-lg border-midnight w-full text-center p-2 mb-8"
                />

                {/* Phone */}
                <label className='mb-4 font-display text-xl text-center'>Phone - ##########</label>
                <input 
                    onChange={ (e) => handleChange(e) }
                    type="text" 
                    id="phone" 
                    name="phone" 
                    className="border-2 rounded-lg border-midnight w-full text-center p-2 mb-8"
                />

                {/* Roles */}
                <label className='mb-4 font-display text-xl text-center'>Role</label>
                <select 
                    onChange={ (e) => handleChange(e) }
                    id="role" 
                    name="role" 
                    className="border-2 rounded-lg border-midnight w-full text-center p-2 mb-8"
                >
                    <option value={ 0 }>Regular User</option>
                    <option value={ 1 }>Admin</option>
                </select>

                {/* Organizations */}
                <label className='mb-4 font-display text-xl text-center'>Organization</label>
                <select 
                    onChange={ (e) => handleChange(e) }
                    id="organization" 
                    name="organization" 
                    className="border-2 rounded-lg border-midnight w-full text-center p-2 mb-8"
                >
                    { orgJSX }
                </select>

                {/* Create */}
                <Button onClick={ attemptCreation }>
                    <h2 className='text-lg font-bold font-display mt-5 mb-5'>
                        Create
                    </h2>
                </Button>

                {/* Alerts */}
                { (message) ? 
                        <p className={
                            'italic mt-8 mb-5 ' + ((success) ? 'text-green-500' : 'text-red-500')
                        }>
                            { message }
                        </p>
                    : []
                }
            </div>
        </Body>
    );
}

// Export for imports
export default User;
