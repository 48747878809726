// React
import React, { useContext, useState } from "react";

// Get the authentication context
import authContext from "../auth/Context.js";

// Get common components
import Nav from "../nav/Nav.js";
import Footer from "../nav/Footer.js";
import Loading from "../loading/Loading.js";
import Button from "../common/Button.js";

// List all available campaigns
function AllCampaigns() {
  // Start the list of results on the first page
  const [selectedResults, setSelectedResults] = useState(1);
  const [message, setMessage] = useState(false);
  const [success, setSuccess] = useState(false);

  // Change the set of 10 results we are viewing
  const changeCampaigns = (i) => {
    var newSelection = i + selectedResults;
    setSelectedResults(newSelection);
  };

  // Get user information from the context
  const authContent = useContext(authContext);
  var campaigns = [];
  if (authContent.campaigns) {
    var accessible = authContent.campaigns;
    var reordered = [...accessible].reverse();

    // Slice the results so we only show 10
    var maxSlice = selectedResults * 10;
    var minSlice = maxSlice - 10;
    campaigns = reordered.slice(minSlice, maxSlice);

    // Find out how many pages there are
    var pages = Math.ceil(accessible.length / 10);
  }

  // Delete a campaign
  const deleteCampaign = (id) => {
    console.log(id);

    // Authenticate with the API
    fetch("/auth/campaign_deletion", {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ id: id }),

      // If the response is a failure, update that message
    }).then((res) => {
      res.json().then((data) => {
        setMessage(data.message);
      });
      if (res.status === 200) {
        setSuccess(true);
      }
    });
  };

  // Return the layout for the campaign list
  return (
    <div>
      <Nav />
      <div className="pt-24 pb-8 min-h-screen w-screen flex flex-col items-center justify-center text-center">
        <h1 className="w-10/12 text-intrvl font-normal text-2xl md:text-3xl tracking-widest mb-2 uppercase">
          ALL CAMPAIGNS
        </h1>
        <p className="w-10/12 italic text-md mb-10">
          Select a campaign to view its media measurement results.
        </p>

        {/* Display all campaigns in a table */}
        {campaigns.length > 0 ? (
          <div className="w-full flex flex-col items-center justify-center text-center">
            <table className="w-10/12 text-xs md:text-base border bg-white shadow-lg table-fixed mb-10">
              <thead>
                <tr>
                  <th className="p-5 w-4/12 text-left">Campaign</th>
                  <th className="p-5 w-4/12">Delete</th>
                  <th className="p-5 w-4/12">Organization</th>
                  <th className="p-5 w-4/12">Delivered</th>
                  <th className="p-5 w-4/12">View</th>
                </tr>
              </thead>
              <tbody>
                {campaigns.map((item) => (
                  <tr className="border-t" key={item.id}>
                    <td className="p-5 font-bold text-left">{item.name}</td>
                    <td className="p-5">
                      <a onClick={() => deleteCampaign(item.id)}>
                        <Button addClasses="w-full">
                          <p className="p-3 font-bold text-center">Delete</p>
                        </Button>
                      </a>
                    </td>
                    <td className="p-5">{item.org}</td>
                    <td className="p-5">{item.created}</td>
                    <td className="p-5">
                      <a href={"/results/" + item.id}>
                        <Button addClasses="w-full">
                          <p className="p-3 font-bold text-center">Results</p>
                        </Button>
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            {/* Navigation commands */}
            {pages > 1 ? (
              <div className="w-full grid grid-cols-3 gap-5 md:gap-0 justify-items-center flex items-center content-center mb-5">
                {selectedResults > 1 ? (
                  <div
                    onClick={() => changeCampaigns(-1)}
                    className="cursor-pointer"
                  >
                    <Button>
                      <p className="p-3 font-bold">{"< Previous"}</p>
                    </Button>
                  </div>
                ) : (
                  <div />
                )}
                <div>
                  <p className="italic text-md">Page {selectedResults}</p>
                </div>
                {selectedResults !== pages ? (
                  <div
                    onClick={() => changeCampaigns(1)}
                    className="cursor-pointer"
                  >
                    <Button>
                      <p className="p-3 font-bold">{"Next >"}</p>
                    </Button>
                  </div>
                ) : (
                  <div />
                )}
              </div>
            ) : (
              <div />
            )}

            {/* Close out the table */}
          </div>
        ) : (
          <div className="w-full flex flex-col items-center justify-center text-center">
            <div className="w-10/12 border bg-white shadow-lg mb-10">
              <Loading />
            </div>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
}

// Export for imports
export default AllCampaigns;
