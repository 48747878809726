// React
import React from 'react';

// Button for all pages
function Button(props) {
    const onClick = props.onClick;
    const disabled = props.disabled;
    const addClasses = props.addClasses;
    const type = props.type;
    const key = props.key;

    // Put classes in a string... because there may be a lot of changes
    var classes = `
        border shadow-lg
        hover:text-white hover:bg-intrvl
        bg-gray-300 bg-opacity-50
        rounded-lg
        pl-5 pr-5
        text-center md:text-left
        cursor-pointer
    `;

    // If the button is disabled, disable pointer events
    if (disabled) {
        classes = [classes, 'pointer-events-none'].join(' ');
    };

    // Add additional classes
    if (addClasses) {
        classes = [classes, addClasses].join(' ');
    };

    // Return the layout for the button
    return (
        <button 
            onClick={ onClick ? () => onClick() : function(){} }  // Empty function if not there
            className={ classes }
            type={ type }
            key={ key }
        >
            { props.children }
        </button>
    );
}
  
// Export for imports
export default Button;