// React
import React from 'react';
  
// Get images
import loadingIcon from '../images/intrvl_line_loading.svg';

// Loading icon and text
function Loading() {

// Return the layout for the campaign list
    return (
        <div>
            <img 
                src={ loadingIcon }
                 alt='Loading Icon' 
                 className='object-contain w-40 mx-auto pt-10 pb-5'
            />
            <p className='italic text-md mb-10'>
                Loading...
            </p>
        </div>
    );
}

// Export for imports
export default Loading;
