// React
import React, {
    useState,
    useEffect
} from 'react';

// Reroute on failure
import { useHistory } from 'react-router-dom';

// Import the context
import authContext from './Context.js';

// Wrapper for the provider
const AuthProvider = ({ children }) => {
    const history = useHistory();
    const [currentUser, setCurrentUser] = useState({
        email: null,
        id: 0,
        name: null,
        organizations: [{
            name: null
        }],
        role: 0,
        campaigns: []
    });

    // Ask the API for the current user
    useEffect(() => {

        // Fetch the data
        fetch('/auth/current_user', {
            method: 'post',
            headers: { 'Content-Type': 'application/json' }

        // Redirect to login page if the response is 403
        }).then(res => {
            if (res.ok) {
                return res.json();
            } else {
                history.push('/user/login');
            };
        
        // Set the user information
        }).then(data => {
            setCurrentUser(data);
        });

    // Refresh on history changing
    }, [history]);

    // Return the authentication context to children
    const { Provider } = authContext;
    return(
        <Provider value={ currentUser }>
            { children }
        </Provider>
    );
};

// Export for imports
export default AuthProvider;