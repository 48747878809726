// Use the common functions throughout the components
const Common = {

    // Return a list of options for dropdowns if the data is available
    dropdownOptions: function(data, question = false, mapping = false) {
        var options = []
        if (data) {
            var sorted = data.sort();
            for (var i in sorted) {

                // Nothing special happens for questions
                if (question === false) {
                    options.push(
                        <option 
                            key={ i } 
                            label={ sorted[i] }
                        >
                            { sorted[i] }
                        </option>
                    );
                } else if (mapping !== false) {

                    // Map the group to its predictive value
                    var pred = mapping[question][sorted[i]]
                    options.push(
                        <option 
                            key={ i } 
                            label={ sorted[i] + ' (Rank: ' + pred + ')' }
                        >
                            { sorted[i] }
                        </option>
                    );
                };
            };
        };
        return options;
    },

    // Clean the group name, if it has been altered for better sorting
    groupNameClean: function(str) {
        if (str.indexOf(':') > -1) {
            var parts = str.split(':');
            str = parts[1];
        } else if (str.indexOf('.') > -1) {
            parts = str.split('.');
            str = parts[1];
        };
        return str;
    },
};

// Export the common functions, used in other modules
export default Common;