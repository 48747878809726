// React
import React, {
  useContext,
  useState,
  useEffect
} from 'react';

// Get the authentication context
import authContext from '../auth/Context.js';

// Get images
import lineDynamic from '../images/intrvl_line_dynamic.svg';

// Get common components
import Nav from '../nav/Nav.js';
import Footer from '../nav/Footer.js';
import Button from '../common/Button.js';

// Homepage for the app to route to recent campaigns
function Home() {

  // State for when to display home
  const [display, setDisplay] = useState(false);

  // Get user information from the context
  const authContent = useContext(authContext);
  var campaigns = [];
  if (authContent.campaigns) {
    campaigns = authContent.campaigns.slice(-1);
  };

  // Use the context to trigger the display
  useEffect(() => {
    if (authContent.id === 0) {
      setDisplay(true);
    };
  }, [authContent]);

  // Just return the nav and footer if we don't know a name
  if (display === false) {
    return (
      <div>
        <Nav />
        <Footer />
      </div>
    )
  };

  // Return the layout for the homepage
  return (
    <div>
      <Nav />
      <div className="pt-24 pb-8 min-h-screen w-screen flex flex-col items-center justify-center text-center">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-5 md:gap-0 justify-items-center content-center mb-5 md:mb-0">
            <div className="md:pl-10 w-9/12 text-center font-light mt-5 md:mt-0">
              <img src={ lineDynamic } alt='INTRVL Logo' className='object-contain w-4/12 mx-auto'></img>
              <h1 className='text-intrvl font-normal text-2xl md:text-3xl mt-3 mb-5 tracking-widest'>
                CONFIDENCE
              </h1>
              <p className='font-bold mb-5'>
                Hello{ (authContent.name) ? ', ' + authContent.name + '!' : '!' }
              </p>
              <p className='italic'>
                To view the media measurement results for your
                most recent { authContent.organizations[0].name } ad campaign,
                please click the campaign name
                <span className='hidden md:inline'> to the right.</span>
                <span className='inline md:hidden'> below.</span> To view
                older media measurement results,
                click <a className='text-intrvl underline' href="/all_campaigns">All Campaigns</a>.
              </p>
            </div>
            <div className="md:pr-10 w-9/12 text-center grid grid-cols-1 gap-5 content-center mt-5 md:mt-0">
              <p className='font-bold mb-0'>Most Recent Results</p>
              { campaigns.map((item) => (
                <div key={ item.id }>
                  <a href={ '/results/' + item.id }>
                    <Button addClasses='w-full'>
                      <h2 className='text-lg font-bold mt-5'>{ item.name }</h2>
                      <p className='text-md mb-5'>Results Delivered: { item.created }</p>
                    </Button>
                  </a>
                </div>
              )) }
            </div>
          </div>
        </div>
        <Footer />
      </div>
  );
}

// Export for imports
export default Home;
