// React
import React, {
    useState
} from 'react';  

// Get common components
import Body from '../common/Body.js';
import Button from '../common/Button.js';

// Organization creation
function Organization() {

    // Send the organization form for validation
    const [orgForm, setOrgForm] = useState({
        name: false
    });
    const [message, setMessage] = useState(false);
    const [success, setSuccess] = useState(false);

    // Handle the form changes
    const handleChange = (e) => {
        var id = e.target.id;
        var value = e.target.value;
        var newData = {...orgForm};
        newData[id] = value
        setOrgForm(newData);
    };

    // Attempt the organization creation
    const attemptCreation = () => {
        
        // Authenticate with the API
        fetch('/auth/org_creation', {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(orgForm)

        // If the response is a failure, update that message
        }).then(res => {
            res.json().then(data => {
                setMessage(data.message);
            });
            if (res.status === 200) {
                setSuccess(true);
            };
        });
    };

    // Return the layout for the user creation
    return (
        <Body>
            <div className="
                w-9/12 md:w-4/12
                mb-5 md:mb-0
                grid grid-cols-1 md:gap-0 
                justify-items-center content-center"
            >
                <h1 className='
                    text-intrvl font-normal text-4xl 
                    md:text-3xl 
                    mt-3 mb-8
                    tracking-widest'
                >
                    ORGANIZATION CREATION
                </h1>

                {/* Name */}
                <label className='mb-4 font-display text-xl text-center'>Name</label>
                <input 
                    onChange={ (e) => handleChange(e) }
                    type="text" 
                    id="name" 
                    name="name" 
                    className="border-2 rounded-lg border-midnight w-full text-center p-2 mb-8"
                />

                {/* Create */}
                <Button onClick={ attemptCreation }>
                    <h2 className='text-lg font-bold font-display mt-5 mb-5'>
                        Create
                    </h2>
                </Button>

                {/* Alerts */}
                { (message) ? 
                        <p className={
                            'italic mt-8 mb-5 ' + ((success) ? 'text-green-500' : 'text-red-500')
                        }>
                            { message }
                        </p>
                    : []
                }
            </div>
        </Body>
    );
}

// Export for imports
export default Organization;
