// React
import React from "react";

// Get common components
import Body from "../common/Body.js";
import Button from "../common/Button.js";

// Link to not trigger authContext refresh
import { Link } from "react-router-dom";

// Homepage links for iterating on buttons
const adminLinks = [
  {
    title: "Create User",
    subtitle: "Must Have Unique Email Address",
    link: "/user/user",
  },
  {
    title: "Delete User",
    subtitle: "Must Know User Email Address",
    link: "/user/delete",
  },
  {
    title: "Create Organization",
    subtitle: "Must Have Unique Name",
    link: "/user/organization",
  },
  {
    title: "Create Campaign Results",
    subtitle: "Must Store Results on BQ Table",
    link: "/user/campaign",
  },
  {
    title: "Reset Password",
    subtitle: "Must Remember Old Password",
    link: "/user/password",
  },
  {
    title: "Edit Group Names",
    subtitle: "Must Download and Upload Group CSV",
    link: "/user/group_name",
  },
  {
    title: "Edit Variable Names",
    subtitle: "Must Download and Upload Variable CSV",
    link: "/user/variable_name",
  },
];

// Show adming settings
function Settings() {
  return (
    <Body>
      <div
        className="
                w-9/12 md:w-4/12
                mb-5 md:mb-0
                grid grid-cols-1
                justify-items-center content-center"
      >
        <h1
          className="
                    text-intrvl font-normal text-4xl 
                    md:text-3xl 
                    tracking-widest mb-2 mt-4"
        >
          ADMIN SETTINGS
        </h1>
        <p className="italic mt-0 mb-6">
          These functions make changes to Confidence's user and organization
          database.
        </p>
        {adminLinks.map((item, i) => (
          <Link to={item.link} key={i} className="w-full mb-4">
            <Button addClasses="w-full">
              <h2 className="text-lg font-bold font-display mt-5">
                {item.title}
              </h2>
              <p className="text-md mb-5">{item.subtitle}</p>
            </Button>
          </Link>
        ))}
      </div>
    </Body>
  );
}

// Export for imports
export default Settings;
