// React
import React, {
    useContext,
    useEffect,
    useState
} from 'react';  

// Get common components
import Body from '../common/Body.js';
import Button from '../common/Button.js';

// Import the context
import authContext from '../auth/Context.js';

// Reroute on login
import { useHistory } from 'react-router-dom';

// Login
function Login() {
    const history = useHistory();

    // Send the login form for validation
    const [loginForm, setLoginForm] = useState({
        email: false,
        password: false,
        remember: false,
        verify: false
    });
    const [failed, setFailed] = useState(false);
    const [success, setSuccess] = useState(false);
    const [phoneModal, setPhoneModal] = useState(false);

    // Get user information from the context
    const authContent = useContext(authContext);

    // Handle the form changes
    const handleChange = (e) => {
        var id = e.target.id;
        var value = e.target.value;
        if (e.target.type === 'checkbox') {
            value = e.target.checked;
        };
        var newData = {...loginForm};
        newData[id] = value
        setLoginForm(newData);
    };

    // Attempt the login
    const attemptLogin = () => {
        
        // Authenticate with the API
        fetch('/auth/login', {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(loginForm)

        // If the response is a failure, update that message
        }).then(res => {
            res.json().then(data => {
                if (res.status === 202) {
                    setFailed(false);
                    setPhoneModal(true);
                } else if (res.status === 200) {
                    if (data.logins > 1) {
                        setSuccess('homepage');  // In useEffect, push to the new page
                    } else {
                        setSuccess('password');
                    }
                } else {
                    setFailed(data.message);
                }
            });
        });
    };

    // On success, go to index, if the use is not new
    useEffect(() => {
        if (success === 'homepage') {
            history.push('/index');
            history.go();

        // New users go to password reset page
        } else if (success === 'password') {
            history.push('/user/password');
            history.go();
        };
    }, [history, success]);

    // Trigger the phone modal
    useEffect(() => {
        if (phoneModal) {
            window.scrollTo(0, 0);  // Scroll up to where the modal is
            document.body.style.overflow = 'hidden';  // Stop scroll
        } else {
            document.body.style.overflow = 'auto';  // Stop scroll
        };
    }, [phoneModal]);

    // Return the layout for the login
    return (
        <Body>
            <div className="
                w-9/12 md:w-4/12
                mb-5 md:mb-0
                grid grid-cols-1 md:gap-0 
                justify-items-center content-center"
            >
                <h1 className='
                    text-intrvl font-normal text-4xl 
                    md:text-3xl 
                    mt-3 mb-8
                    tracking-widest'
                >
                    LOGIN
                </h1>

                {/* Email */}
                <label className='mb-4 font-display text-xl text-center'>Email</label>
                <input 
                    onChange={ (e) => handleChange(e) }
                    type="text" 
                    id="email" 
                    name="email" 
                    className="border-2 rounded-lg border-midnight w-full text-center p-2 mb-8"
                />

                {/* Password */}
                <label className='mb-4 font-display text-xl text-center'>Password</label>
                <input 
                    onChange={ (e) => handleChange(e) }
                    type="password" 
                    id="password" 
                    name="password" 
                    className="border-2 rounded-lg border-midnight w-full text-center p-2 mb-8"
                />

                {/* Remember */}
                <div className='form-check mb-8'>
                    <input 
                        onChange={ (e) => handleChange(e) }
                        type="checkbox" 
                        id="remember" 
                        name="remember" 
                        className="form-check-input mr-3"
                    />
                    <label className='
                        mb-4 font-display text-xl text-center
                        form-check-label
                    '>Remember Me</label>
                </div>

                {/* Login */}
                <Button 
                    onClick={ attemptLogin }
                    disabled={ (authContent) ? true : false }
                >
                    <h2 className='text-lg font-bold font-display mt-5 mb-5'>
                        Login
                    </h2>
                </Button>

                {/* Alerts */}
                { (failed && (phoneModal === false)) ? 
                        <p className='text-red-500 italic mt-8'>
                            { failed }
                        </p>
                    : []
                }
                { (authContent) ? 
                        <p className='text-red-500 italic mt-8 mb-5'>
                            Already logged in as { authContent.name } - { authContent.email }.
                        </p>
                    :
                        <p className="italic mb-5 mt-8">
                            If this is your first login attempt, 
                            you will be required to update your password.
                        </p>
                }
            </div>

            {/* Modal for Twilio 2FA */}
            { (phoneModal) ? 
                <div 
                    className={"absolute top-0 left-0 z-10 min-h-screen w-screen " +
                        " bg-midnight bg-opacity-50 flex items-center content-center"
                }>
                    <div className="
                        w-9/12 md:w-6/12
                        bg-white
                        ml-auto mr-auto
                        pt-5 pb-5
                        flex flex-col justify-center items-center text-center
                        rounded-lg
                    ">
                        
                        {/* Verification */}
                        <label className='mb-4 font-display text-xl text-center'>
                            Verification Code
                        </label>
                        <input 
                            onChange={ (e) => handleChange(e) }
                            type="text" 
                            id="verify" 
                            name="verify" 
                            className="border-2 rounded-lg border-midnight w-9/12 md:w-4/12 text-center p-2 mb-8"
                        />

                        {/* Login */}
                        <Button 
                            onClick={ attemptLogin }
                            disabled={ (authContent) ? true : false }
                            addClasses={ 'mb-8' }
                        >
                            <h2 className='text-lg font-bold font-display mt-5 mb-5'>
                                Verify
                            </h2>
                        </Button>

                        {/* Failure */}
                        { (failed && phoneModal) ? 
                                <p className='text-red-500 italic mb-6'>
                                    { failed }
                                </p>
                            : []
                        }

                        {/* Explain */}
                        <p className="italic pl-2 pr-2 mt-0">
                            Please check the cellphone associated with your account
                            for a six-digit verification code.<br/>
                            Enter that code here to verify access to your account.
                        </p>
                    </div>
                </div>
            : [] }
        </Body>
    );
}

// Export for imports
export default Login;
