// React
import {
    useEffect
} from 'react';  

// Reroute on login
import { useHistory } from 'react-router-dom';

// Logout
function Logout() {
    const history = useHistory();

    // Immediately logout the user
    useEffect(() => {

        // Authenticate with the API
        fetch('/auth/logout', {
            method: 'post',
            headers: { 'Content-Type': 'application/json' }

        // Upon success return to login page
        }).then(res => {
            if (res.status === 200) {
                history.push('/');
                history.go();
            };
        });
    }, [history]);

    // No layout due to the redirect in useEffect
    return null;
}

// Export for imports
export default Logout;
