// React
import React from 'react';
  
// Get common components
import Nav from '../nav/Nav.js';
import Footer from '../nav/Footer.js';

// Body for all pages with correct padding on the navbars
function Body(props) {

    // Return the layout for centering the body
    return (
        <div>
            {/* Navigation menu */}
            <Nav />  

            {/* Padding for menu */}
            <div className="
                pt-24 m:pt-16 pb-8 
                min-h-screen w-screen
                flex flex-col
                items-center justify-center text-center
            ">

                {/* All JSX passed in for body */}
                { props.children }
            </div>

            {/* Body */}
            <Footer />
        </div>
    );
}
  
// Export for imports
export default Body;