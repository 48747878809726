// React
import React, {
    useState,
    useEffect
  } from 'react';

// Loading
import Loading from '../loading/Loading.js';

// Common functions
import Common from '../common/Common.js';

// Plotly
import Plot from 'react-plotly.js';

// Resizing
import useResizeObserver from 'use-resize-observer';

// Display groups for a campaign
function Groups(props) {
    const id = props.id;
    const size = props.size;

    // State for storing results from the API
    const [groupResults, setGroupResults] = useState(false);
    const [descriptions, setDescriptions] = useState({});
    const [rankings, setRankings] = useState(false);

    // State for storing using selections
    const [question, setQuestion] = useState(false);
    const [group, setGroup] = useState(false);
    const [exclusions, setExclusions] = useState([]);

    // Get the resizing info
    const { ref, width = 1, height = 1 } = useResizeObserver();

    // Ask the API for the group data
    useEffect(() => {

        // Fetch the data
        if (id) {
            fetch('/data/group_results', {
                method: 'post',
                body: JSON.stringify({
                'campaign-id': id
                }),
                headers: { 'Content-Type': 'application/json' }
            }).then(res => res.json()).then(data => {

                // Set the descriptions
                setDescriptions(data.mappings);

                // Set the first question alphabetically
                var questionSort = data.questions.sort();
                setQuestion(questionSort[0]);

                // Set the first group alphabetically
                var groupSort = data.groups.sort();
                setGroup(groupSort[0]);

                // Add the data to the results object
                setGroupResults(data);
            });
        };
    
    // Update when campaign id changes
    }, [id]);

    // Ask the API for the ranking data for the importance of groups
    useEffect(() => {

        // Fetch the data
        if (id) {
            fetch('/data/feature_rankings', {
                method: 'post',
                body: JSON.stringify({
                'campaign-id': id
                }),
                headers: { 'Content-Type': 'application/json' }
            }).then(res => res.json()).then(data => {

                // Add the data to the ranking object
                setRankings(data);
            });
        };
    
    // Update when id changes
    }, [id]);

    // Reset the question on form change
    const handleQuestion = (event) => { 
        var value = event.target.value;
        setQuestion(value);
    };

    // Reset the group on form change
    const handleGroup = (event) => { 
        var value = event.target.value;
        setGroup(value);
        setExclusions([]);
    };

    // Update the exclusion list
    const updateExclusion = (event) => { 
        var value = event.target.value;
        if (exclusions.includes(value)) {
            setExclusions(exclusions.filter(x => x !== value));
        } else {
            setExclusions(current => [...current, value]);
        }
    };

    // Get options for the question and group dropdowns
    var questionOptions = Common.dropdownOptions(groupResults.questions);
    var groupOptions = Common.dropdownOptions(groupResults.groups, question, rankings);

    // The bar chart data requires Plotly settings, so make the empty object with those settings
    var barControlData = {
        name: 'Control',
        type: 'bar',
        x: [],
        y: [],
        text: [],
        hoverinfo: 'skip',
        texttemplate: '%{y:.1%}',
        textfont: { size: 12 },
        marker: { color: '#E7EAED' },
    };
    var barTreatmentData = {
        name: 'Treatment',
        type: 'bar',
        x: [],
        y: [],
        text: [],
        hoverinfo: 'skip',
        texttemplate: '%{y:.1%}',
        textfont: { size: 12 },
        marker: { color: '#3dbaff' },
    };

    // The box and donut chart data are simple arrays
    var boxData = [];
    var donutGroups = [];
    var donutValues = [];

    // The KPI data includes numbers which will be updated in the iteration
    var positiveKPIs = 0;
    var totalKPIs = 0;
    var positiveGroups = [];
    var negativeGroups = [];

    // Vars are populated in the checkbox for selection of available visualizations
    var variables = [];

    // Table data is an array of objects over which we will iterate
    var tableData = [];

    // When the results, question, and group are available, update those empty arrays
    if (groupResults && question && group) {
        var results = groupResults.output[question][group]
        results.group.forEach(function (value, i) {

            // Add the variables to the dictionary
            variables.push(Common.groupNameClean(value));

            // Push the treatment and control data for the bar chart
            if (!exclusions.includes(Common.groupNameClean(value))) {
                var xLabel = value;
                barTreatmentData['x'].push(
                    Common.groupNameClean(xLabel)
                );
                barTreatmentData['y'].push(
                    results['avg_treatment'][i]
                );
                barTreatmentData['text'].push(
                    results['avg_treatment'][i]
                );
                barControlData['x'].push(
                    Common.groupNameClean(xLabel)
                );
                barControlData['y'].push(
                    results['avg_control'][i]
                );
                barControlData['text'].push(
                    results['avg_control'][i]
                );

                // Make the name for the box
                var boxName = Common.groupNameClean(value) + '<br>avg lift: ' + ((Math.round(results['avg_lift'][i] * 1000) / 10)) + 'pp';
                if (results['lift_prob'][i] > 0.95) {
                    boxName += ' ***';
                } else if (results['lift_prob'][i] > 0.9) {
                    boxName += ' **';
                } else if (results['lift_prob'][i] > 0.8) {
                    boxName += ' *';
                } else if (results['lift_prob'][i] > 0.67) {
                    boxName += ' ^';
                }

                // For the box plot, push the data with settings for the individual boxes
                boxData.push({
                    y: [
                        results['avg_lift'][i],
                        results['lift_low_05'][i],
                        results['lift_low_10'][i],
                        results['lift_high_90'][i],
                        results['lift_high_95'][i],
                    ],
                    name: boxName,
                    type: 'box',
                    marker: { color: '#3dbaff' },
                    fillcolor: 'rgba(209, 213, 219, 0.5)',
                    hoverinfo: 'skip',
                    text: results['avg_lift'][i]
                });

                // Push the donut chart data
                donutGroups.push(Common.groupNameClean(value));
                donutValues.push(Math.round(results['people_prop'][i] * 10000 / 100));

                // Determine if the KPI is positive or negative for the display
                if (results['avg_lift'][i] >= 0) {
                    positiveKPIs += 1;
                    positiveGroups.push(value);
                };
                if (results['avg_lift'][i] < 0) {
                    negativeGroups.push(value);
                };
                totalKPIs += 1;

                // Table data consists of an array of objects, so push that data
                tableData.push({
                    'name': Common.groupNameClean(value),
                    'avg_lift': results['avg_lift'][i],
                    'avg_control': results['avg_control'][i],
                    'avg_treatment': results['avg_treatment'][i],
                    'lift_prob': results['lift_prob'][i],
                    'size': Math.round(results['people_prop'][i] * size),
                    'n_lift': Math.round(results['people_prop'][i] * size * results['avg_lift'][i]),
                });
            }
        })
    }

    // Get the KPI ratio
    var ratioKPIs = positiveKPIs / totalKPIs;

    // Return the layout for the groups view
    return (
        <div>
            <div className="
                grid 
                grid-cols-1 md:grid-cols-6 
                gap-5 
                justify-items-center 
                content-center
            ">

                {/* Map the questions */}
                <div className='
                    col-span-1 md:col-span-3 
                    flex
                    w-full
                    border
                    bg-white
                    shadow-lg
                    text-center
                '>
                    <p className='w-4/12 text-xl mt-5 mb-5 p-2'>
                        Question
                    </p>
                    <select 
                        className="
                            minimal 
                            form-select
                            border 
                            w-8/12
                            mb-5 mt-5 mr-5
                            bg-white
                            rounded-lg
                            cursor-pointer
                            text-md
                            text-center
                            p-2
                        "
                        onChange={ (event) => handleQuestion(event) }
                    >
                        { questionOptions }
                    </select>
                </div>

                {/* Map the groups */}
                <div className='
                    col-span-1 md:col-span-3 
                    flex 
                    w-full
                    border
                    bg-white
                    shadow-lg
                    text-center'
                >
                    <p className='w-4/12 text-xl mt-5 mb-5 p-2'>
                        Group
                    </p>
                    <select 
                        className="
                            minimal 
                            form-select
                            border 
                            w-8/12
                            mb-5 mt-5 mr-5
                            bg-white
                            rounded-lg
                            cursor-pointer
                            text-md
                            text-center
                            p-2
                        "
                        onChange={ (event) => handleGroup(event) }
                    >
                        { groupOptions }
                    </select>
                </div>

                {/* Allow for the removal of values */}
                <div className='
                    col-span-1 md:col-span-6
                    flex 
                    w-full
                    border
                    bg-white
                    shadow-lg
                    text-center'
                >
                    <p className='w-full text-xl mt-5 mb-5 p-2'>
                        Displayed Data
                    </p>
                    { variables.map((name, i) => (
                        <div key={ i } className='w-3/12 flex flex-col justify-center text-center'>
                            <input 
                                type='checkbox'
                                onChange={ updateExclusion } 
                                value={ name } 
                                checked={ (exclusions.includes(name)) ? false : true }
                                id={ 'check' + i }
                            >
                            </input>
                            <label htmlFor={ 'check' + i }>{ name }</label>
                        </div>
                    ))}
                </div>

                {/* Box plot of 80% MoEs */}
                <div
                    className='
                        col-span-1 md:col-span-4 
                        w-full 
                        border 
                        bg-white
                        shadow-lg 
                        text-center
                '>
                    <p className='font-normal text-xl mt-5'>
                        Lift from Treatment by { group ? group : 'Group' }
                    </p>
                    { (groupResults) ? 
                        <div>
                            <div className='plotly-small-height mb-3'>
                                    <Plot
                                        data={ boxData }
                                        layout={{ 
                                            width: width, 
                                            height: height,
                                            dragmode: false,
                                            paper_bgcolor: 'rgba(0, 0, 0, 0)',
                                            margin: {
                                                l: 100,
                                                r: 50,
                                                t: 50,
                                                b: 50
                                            },
                                            font: {
                                                family: "Open Sans",
                                                color: 'black',
                                                size: 12,
                                            },
                                            yaxis: {
                                                tickformat: ',.0%',
                                                title: 'Lift'
                                            },
                                            showlegend: false
                                        }}
                                        config={
                                            {
                                                displayModeBar: false,
                                            }
                                        }
                                    />
                            </div>
                            <p className='font-normal italic text-sm m-5'>
                                For each group, the middle line in each box
                                shows the average lift estimate,
                                the top and bottom of the boxes show
                                the 80% margin of error estimates,
                                and the whiskers outside the boxes
                                show the 90% margin of error estimates.
                                Lift probabilities are indicated by the following symbols:
                                >67%: ^, >80%: *, >90%: **, >95%: ***.
                            </p>
                        </div>
                    : <Loading /> }
                </div>

                {/* Display the positive and negative KPIs */}
                <div
                    className='
                        col-span-1 md:col-span-2
                        w-full
                        border
                        bg-white
                        shadow-lg
                        flex
                        flex-col
                        items-center
                        justify-center
                        text-center
                        p-5
                '>
                    { (groupResults) ?
                        <p
                            className={
                                (ratioKPIs >= 0.5) ? 'text-good mb-5 mt-5 text-3xl font-bold'
                                : 'text-bad mb-5 mt-5 text-3xl font-bold'
                            }>
                            <span className='text-8xl'>{positiveKPIs}</span>
                            <br />
                            out of {totalKPIs}
                        </p>
                    : 
                        <Loading />
                    }
                    <p className='font-normal text-xl mb-5'>
                        Groups<br />with Positive Lift
                    </p>
                    { (groupResults) ? <div>
                        { ((group in descriptions) && rankings) ?
                            <div>
                            <p className='italic text-sm text-center mb-0'>
                                Description: { descriptions[group].groups_desc }.
                                When assessing the most important explanatory variables
                                for the question <i>{ question }</i>,
                                this variable ranks { rankings[question][group] }.
                            </p>  
                            </div>
                        :
                            <div />        
                        }
                    </div> :
                        []
                    }
                </div>

                {/* Sizes of the audience within the donut chart */}
                <div
                    className='
                        col-span-1 md:col-span-2 
                        w-full
                        border
                        bg-white
                        shadow-lg
                        text-center
                '>
                    <p className='font-normal text-xl mt-5'>
                        Size by { group ? group : 'Group' }
                    </p>
                    { (groupResults) ? 
                        <div>
                            <div className='plotly-small-height mb-3'>
                                <div style={{ width: '50%', height: '100%' }} ref={ref}>
                                    <Plot
                                        data={[
                                            {
                                                type: 'pie',
                                                values: donutValues,
                                                labels: donutGroups,
                                                textfont: {
                                                    color: 'black'
                                                },
                                                hoverinfo: 'skip',
                                                marker: {
                                                    colors: [
                                                        '#3dbaff',
                                                        '#845C74',
                                                        '#FFCB47',
                                                        '#73AB84',
                                                        '#EB5E28'
                                                    ]
                                                },
                                                hole: 0.4,
                                                sort: false
                                            }
                                        ]}
                                        layout={
                                            {
                                                width: width / 2,
                                                height: height,
                                                dragmode: false,
                                                paper_bgcolor: 'rgba(0, 0, 0, 0)',
                                                margin: {
                                                    l: 50,
                                                    r: 50,
                                                    t: 50,
                                                    b: 50
                                                },
                                                font: {
                                                    family: "Open Sans",
                                                    color: 'black',
                                                    size: 12,
                                                },
                                                legend: {
                                                    orientation: 'h',
                                                    xanchor: 'center',
                                                    x: 0.5
                                                }
                                            }
                                        }
                                        config={
                                            {
                                                displayModeBar: false,
                                            }
                                        }
                                    />
                                </div>
                            </div>
                            <p className='font-normal italic text-sm m-5'>
                                Each slice represents the proportion of each group
                                in the treatment audience.
                            </p>
                        </div>
                    : <Loading /> }
                </div>

                {/* Group levels for treatment and control */}
                <div
                    className='
                        col-span-1 md:col-span-4
                        w-full
                        border
                        bg-white
                        shadow-lg
                        text-center
                '>
                    <p className='font-normal text-xl mt-5'>
                        Control and Treatment Levels by { group ? group : 'Group' }
                    </p>
                    { (groupResults) ? 
                        <div>
                            <div className='plotly-small-height mb-3'>
                                <div style={{ width: '100%', height: '100%' }} ref={ref}>
                                    <Plot
                                        data={ [barControlData, barTreatmentData] }
                                        layout={{ 
                                            width: width, 
                                            height: height,
                                            dragmode: false,
                                            paper_bgcolor: 'rgba(0, 0, 0, 0)',
                                            margin: {
                                                l: 100,
                                                r: 75,
                                                t: 50,
                                                b: 25
                                            },
                                            font: {
                                                family: "Open Sans",
                                                color: 'black',
                                                size: 12,
                                            },
                                            yaxis: {
                                                tickformat: ',.0%',
                                                title: 'Average Level'
                                            },
                                            legend: {
                                                orientation: 'v',
                                            }
                                        }}
                                        config={
                                            {
                                                displayModeBar: false,
                                            }
                                        }
                                    />
                                </div>
                            </div>
                            <p className='font-normal italic text-sm m-5'>
                                Average levels estimate the proportion of 
                                the audience group holding the specified view for each
                                survey question KPI after media exposure
                                and estimate the proportion had the treatment audience
                                not been exposed.
                            </p>
                        </div>
                    : <Loading /> }
                </div>

                {/* Table of the results */}
                <div className='
                    col-span-1 md:col-span-6
                    w-full
                    table-fixed
                    border
                    bg-white
                    shadow-lg
                '>
                    <p className='font-normal text-xl mt-5 mb-5'>
                        Lift Model Output by { group ? group : 'Group' }
                    </p>
                    { (groupResults) ?
                        <div>
                            <table className="w-full text-xs md:text-base">
                                <thead>
                                    <tr>
                                        <th className='pb-5 pt-5 w-2/12'>Group<br />Name</th>
                                        <th className='pb-5 pt-5 w-2/12'>Avg<br />Lift</th>
                                        <th className='pb-5 pt-5 w-2/12'>Lift<br />Prob</th>
                                        <th className='pb-5 pt-5 w-2/12'>Group<br />Size</th>
                                        <th className='pb-5 pt-5 w-2/12'>Number<br />Lifted</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    { tableData.map((row, i) => (
                                        <tr className='border-t' key={ i }>
                                            <td className='pb-5 pt-5'>{
                                                row.name
                                            }</td>
                                            <td className={
                                                (Math.round(row.avg_lift * 1000) / 10 >= 0) ?
                                                    'pb-5 pt-5 text-good' : 'pb-5 pt-5 text-bad'
                                            }>{
                                                Math.round(row.avg_lift * 1000) / 10
                                            }pp</td>
                                            <td className='pb-5 pt-5'>{
                                                Math.round(row.lift_prob * 1000) / 10
                                            }%</td>
                                            <td className='pb-5 pt-5'>{
                                                row.size.toLocaleString()
                                            }</td>
                                            <td className={
                                                (row.n_lift >= 0) ?
                                                    'pb-5 pt-5 text-good' : 'pb-5 pt-5 text-bad'
                                            }>{
                                                row.n_lift.toLocaleString()
                                            }</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <p className='font-normal italic text-sm mb-5 ml-5 mr-5 mt-3'>
                                Average lift is calculated using survey responses
                                from people exposed to the media
                                (treatment) and people not exposed (control).
                                Lift probability is an estimate
                                of the likelihood of positive lift
                                when accounting for survey sampling error.
                                Number lifted is the number of people
                                in the treatment audience estimated
                                to have changed attitudes due to media.
                            </p>
                        </div>
                    : <Loading /> }
                </div>
            </div>
        </div>
    );
}

// Export for imports
export default Groups;
