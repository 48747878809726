// React
import React, {
    useContext
} from 'react';  

// Get common components
import Body from '../common/Body.js';

// Import the context
import authContext from '../auth/Context.js';

// 404 message
function NotFound() {

    // Get user information from the context
    const authContent = useContext(authContext);

    // Return the layout for the 404 message
    return (
        <Body>
            <div className="
                w-9/12 md:w-4/12
                mb-5 md:mb-0
                grid grid-cols-1 md:gap-0 
                justify-items-center content-center"
            >
                <h1 className='
                    text-intrvl font-normal text-4xl md:text-3xl 
                    mt-3 mb-5 
                    tracking-widest
                '>
                    { (authContent) ? 'PAGE NOT FOUND' : 'PLEASE LOGIN' }
                </h1>
                { (authContent) ?
                    <p className='font-display'>
                        Just like the GOP has not found enough support
                        to win the popular vote in a presidential election
                        since 2004...
                    </p>
                :
                    <p className='font-display'>
                        To view this content, please visit
                        the <a className='text-intrvl underline' href="/auth/login">login page</a> to
                        authenticate your session.
                    </p>
                }
            </div>
        </Body>
    );
}

// Export for imports
export default NotFound;
